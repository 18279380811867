/* 
@media only screen and (max-width: 600px)
@media only screen and (min-width: 600px)
@media only screen and (min-width: 768px)
@media only screen and (min-width: 992px)
@media only screen and (min-width: 1200px)
@media only screen and (min-width : 1824px)

@media only screen and (orientation: landscape | portrait) {
   ...
}

*/
/* .footer_3 {
    display: none;
} */
@media only screen and (min-width: 993px) {
    .footer {
        margin-left: auto;
        margin-right: auto; 
        width: 1200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-sizing: border-box;
    }
    .footer_1 {
        display: flex;
        flex-direction: row;
        justify-content: flex-end
    }
    .footer_2 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start
    }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
    .footer {
        margin-left: auto;
        margin-right: auto;
        width: 600px;
        display: flex;
        flex-direction: column
    }
    .footer_1 {
        margin-left: auto;
        margin-right: auto;        
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .footer_2 {
        margin-left: auto;
        margin-right: auto;        
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 600px) {
    .footer_1 {
        display: none;        
    }
    .footer_2 {
        display: none;
    }
    .footer {
        min-height: 55px;
    }
    /* .footer_3 {
        display: flex;
        align-items: center;
        justify-content: center;
    } */
}