.main_header {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 30px;
    background-color: rgba(9, 9, 9, 0.177);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.not-main {
    display: none;
}
.menus {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
}

.nav-link {
    color: rgba(41, 164, 55, 0.831);
    font-size: 20px;
    text-decoration: none;
    margin-right: 15px;
}
.logged-user{
    color: rgba(41, 164, 55, 0.831);
    font-size: 20px;
}
.nav-link:hover {
    color: rgb(192, 20, 20);
}

.nav-link:active {
    text-decoration: underline;
}

.login_corner {
    display: flex;
}