.round_and_score_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid rgba(3, 24, 12, 0.4);
    border-radius: 5px;
    margin-left: 1px;
    margin-right: 1px;
    width: 355px;
    height: 530px;
    /* overflow-x: hidden; */
    overflow-y: hidden;
    font-weight: bold;
    font-size: 40px;
    
}
.top-div {
    height: 528px;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start;
    align-items: flex-start; */
}
.table-head-container {
    position: releativ;
    top: 0;
    left: 0;
    height: 35px;
    margin: 5px;
}
.round_and_score_table {
    position: relative;
    top: 40;
    left: 0;
    max-height: 493px;
    /* padding-top: 5px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* overflow: auto; */
}
.csak_tabla {
    max-height: 493px;
    overflow-x: hidden;
    overflow-y: auto;
    /* max-height: 486px; */
    display: flex;
    /* overflow-x: scroll; */
    /* overflow: scroll; */
    /* overflow-y: auto; */
    /* flex-direction: column;
    justify-content: end; */
}
.bottom-div {
    height: 2px;
}
.round-row {
    overflow-y: auto;
}
.round_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid rgba(3, 24, 12, 0.4);
    border-radius: 5px;
    margin-left: 1px;
    margin-right: 1px;
    width: 70px;
    height: 530px;
    overflow-x: hidden;
    /* overflow-y: auto; */
    font-weight: bold;
    font-size: 40px;
    
}
.left_column {
    font-size: 24px;
    width: 66px;
    padding-right: 10px;
    text-align: center;
}
.right_column {
    font-size: 24px;
    width: 200px;
    padding-right: 10px;
    text-align: center;
}
.center_column {
    font-size: 24px;
    width: 120px;
    padding-right: 20px;
    text-align: center;
}
.left_column_d {
    width: 66px;
    padding-right: 10px;
}
.right_column_d {
    width: 200px;
    padding-right: 10px;
}
.center_column_d {
    width: 120px;
    padding-right: 20px;
}


/* @media screen and (min-width: 993px) {
    
} */
/* @media screen and (min-width: 768px) and (max-width: 992px) {
    .round_and_score_container {
        box-sizing: border-box;
        width: 390px;
        margin-left: 0;
        margin-right: 0;
        height: 280px;
        overflow-x: hidden;
        overflow-y: auto;
        font-weight: bold;
        font-size: 14px;
    }
    .left_column {
        font-size: 14px;
        width: 42px;
        padding-right: 10px;
    }
    .right_column {
        font-size: 14px;
        width: 78px;
        padding-right: 10px;
    }
    .center_column {
        font-size: 14px;
        width: 60px;
        padding-right: 20px;
    }
} */

/* @media screen and (min-width: 601px) and (max-width: 767px) {
    .round_and_score_container {
        box-sizing: border-box;
        width: 180px;
        margin-left: 0;
        margin-right: 0;
        height: 280px;
        overflow-x: hidden;
        overflow-y: auto;
        font-weight: bold;
        font-size: 14px;
    }
    .left_column {
        font-size: 14px;
        width: 42px;
        padding-right: 10px;
    }
    .right_column {
        font-size: 14px;
        width: 78px;
        padding-right: 10px;
    }
    .center_column {
        font-size: 14px;
        width: 60px;
        padding-right: 20px;
    }
} */
@media screen and (max-width: 600px) {
    .round_and_score_container {
        box-sizing: border-box;
        width: 160px;
        height: 280px;
        overflow-x: hidden;
        overflow-y: auto;
        font-weight: bold;
        font-size: 18px;
    }
    .round_container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid rgba(3, 24, 12, 0.4);
        border-radius: 5px;
        margin-left: 1px;
        margin-right: 1px;
        width: 40px;
        height: 280px;
        overflow-x: hidden;
        overflow-y: auto;
        font-weight: bold;
        font-size: 18px;
        
    }
    .left_column {
        font-size: 14px;
        width: 42px;
        padding-right: 10px;
    }
    .right_column {
        font-size: 14px;
        width: 78px;
        padding-right: 10px;
    }
    .center_column {
        font-size: 14px;
        width: 60px;
        padding-right: 20px;
    }
    .left_column_d {
        width: 70px;
        padding-right: 10px;
    }
    .right_column_d {
        width: 130px;
        padding-right: 10px;
    }
    .center_column_d {
        width: 100px;
        padding-right: 20px;
    }
}