
.home_page_login {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    top: 0px;
    display: flex;
    margin-top: 30px;
}
.login-page {
    position: relative;
    width: 360px;
    margin: 80px auto;
    border: 1px solid black;
    border-radius: 20px;
    background-image: linear-gradient(to bottom right,rgba(255, 0, 0, 0.2), white, rgba(0, 128, 0, 0.2));
    font-weight: bold;
}
.my-login-form {
    width: 320px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    margin: 20px auto;
    padding: 20px auto; 
    background-image: linear-gradient(to bottom right,rgba(255, 0, 0, 0.2), white, rgba(0, 128, 0, 0.2));
    font-weight: bold;
}
.my-form-row {
    display: flex;
    flex-direction: row;
    margin: 10px auto;
}
.my-login-form-body {
    width: 360px;
    display: flex;
    flex-direction: column;
}
.login-error-message {
    display: flex;
    justify-content: center;
    color: red;
}
.login-form-label{
    width: 100px;
}
input {
    width: 170px;
}
.btn-login {
    width: 100px;
}
.footer-login{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 320px;
    height: 60px;
    text-align: center;
    padding-top: 10px;
    font-size: 14px;
}
.footer-resend {
    width: 200px;
    text-align: center;
}
/* .login-page {
    width: 360px;
    margin: 50px auto;
    border: 1px solid black;
    border-radius: 20px;
}

.my-login-form {
    width: 320px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    margin: 20px auto;
    padding: 20px auto; 
}
.my-form-row {
    display: flex;
    flex-direction: row;
    margin: 10px auto;
}
.my-login-form-body {
    width: 360px;
    display: flex;
    flex-direction: column;
}
.login-error-message {
    display: flex;
    justify-content: center;
    color: red;
}
.login-form-label{
    width: 100px;
}
input {
    width: 170px;
}
.btn {
    width: 140px;
}
.footer-login{
    width: 200px;
    text-align: center;
} */