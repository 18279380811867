.check_outs_container {
    box-sizing: border-box;
    border: 1px solid rgba(3, 24, 12, 0.4);
    border-radius: 10px;
    width: 300px;
    height: 160px;
    display: flex;
    flex-direction: column;
}
.check_out_text {
    height: 22px;
    font-size: 18px;
    font-weight: bold;
}
.check_out_items {
    height: 138px;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    /* padding-bottom: 15px; */
    color: red;
    font-weight: bold;
    font-size: 44px;
}
.check_out_item {
    height: 45px;
}
@media screen and (min-width: 993px) {
    /* width: 900px; */
}
@media screen and (min-width: 768px) and (max-width: 992px) {
    /* width: 760px; */
}
@media screen and (min-width: 601px) and (max-width: 767px) {
    /* width: 600px; */
}
@media screen and (max-width: 600px) {
    /*  width: 360px; */
    .check_outs_container {
        border: 1px solid rgba(3, 24, 12, 0.4);
        border-radius: 5px;
        width: 125px;
        height: 85px;
        display: flex;
        flex-direction: column;
    }
    .check_out_text {
        height: 16px;
        font-size: 14px;
        font-weight: bold;
    }
    .check_out_items {
        height: 71px;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        color: red;
        font-weight: bold;
        font-size: 14px;
    }
    .check_out_item {
        height: 35px;
    }
}