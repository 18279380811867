.player-results-screen {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.player-results-title {
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
}
.player-result-container {
    display: flex;
    flex-direction: column;
}
.player-select-container {
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}
.player-select {
    width: 360px;
    font-size: 18px;
}
.player-stat-container {
    width: 800px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
}
.player-performance-container {
    box-sizing: border-box;
    width: 360px;
    height: 320px;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: bold;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
}
.player-shotstat-container {
    box-sizing: border-box;
    width: 360px;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: bold;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
}
.p-stats {
    width: 50%;
    height: 50px;
    padding: 20px;
    font-size: 20px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
}
.p-stats-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.p-range-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}