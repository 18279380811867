@media screen and (min-width: 993px) {
    /* width: 900px; */
}
@media screen and (min-width: 768px) and (max-width: 992px) {
    /* width: 760px; */
}
@media screen and (min-width: 601px) and (max-width: 767px) {
    /* width: 600px; */
}
@media screen and (max-width: 600px) {
    /*  width: 360px; */
}
.header {
    /* border: 1px solid red; */
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding: 0 50px 0 50px; */
}