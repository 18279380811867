
/* @import 'ScoreInput.css';
@import 'ScoreAndCheck.css';
@import 'MatchSettings.css';
@import "Footer.css";
@import "Header.css";
@import "GameState.css";
@import "CheckOuts.css";
@import "Stats.css";
@import "RoundAndScore.css";
@import "HotKeys.css"; */


@media only screen and (min-width: 1201px) {
.main_page {
    display: flex;
    height: 100vh;
    width: 1200px;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 0 50px 0 50px; */
    margin-left: auto;
    margin-right: auto
}
}

@media screen and (min-width: 993px) and (max-width:1200px) {
    .main_page {
        display: flex;
        height: 100vh;
        width: 900px;
        flex-direction: column;
        justify-content: space-between;
        /* padding: 0 50px 0 50px; */
        margin-left: auto;
        margin-right: auto
    }   
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .main_page {
        display: flex;
        height: 100vh;
        width: 760px;
        flex-direction: column;
        justify-content: space-between;
        /* padding: 0 50px 0 50px; */
        margin-left: auto;
        margin-right: auto
    }   
}
@media screen and (min-width: 601px) and (max-width: 767px) {
    .main_page {
        display: flex;
        height: 100vh;
        width: 600px;
        flex-direction: column;
        justify-content: space-between;
        /* padding: 0 50px 0 50px; */
        margin-left: auto;
        margin-right: auto
    }   
}
@media screen and (max-width: 600px) {
    .main_page {
        display: flex;
        height: 100vh;
        width: 360px;
        flex-direction: column;
        justify-content: space-between;
        /* padding: 0 50px 0 50px; */
        margin-left: auto;
        margin-right: auto
    }   
}
