.result-title-container {display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    background-color: rgba(239, 242, 244, 0.601);
    font-weight: bold;
    font-size: 20px;
}
.settings-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(239, 242, 244, 0.601);
}
.variant-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
}
.hc1-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
}
.hc2-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
}
.sets-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
}
.legsperset-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
}
.names-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(239, 242, 244, 0.601);
    font-weight: bold;
    font-size: 20px;
}
.result-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
    font-weight: bold;
    font-size: 20px;
}
.details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(239, 242, 244, 0.601);
    font-weight: bold;
    font-size: 20px;
}
.result-details-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(239, 242, 244, 0.601);
}
.detail {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
}
.name1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
}
.name2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
}

.stat-container {
    display: flex;
    flex-direction: column;
    /* padding: 5px; */
}
.stat-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(239, 242, 244, 0.601);
    font-weight: bold;
    font-size: 20px;
}
.averages-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(239, 242, 244, 0.601);
    padding: 5px;
    font-size: 20px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
}
.p1-stats {
    width: 50%;
    height: 50px;
    padding: 20px;
    font-size: 20px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
}
.p1-stats-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.p2-stats {
    width: 50%;
    height: 50px;
    padding: 20px;
    font-size: 20px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
}
.p2-stats-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.shots-range-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(239, 242, 244, 0.601);
    padding: 5px;
}
.p1-shots-range {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    width: 50%;
    font-size: 20px;
    /* margin: 10px; */
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);

}
.p2-shots-range {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    font-size: 20px;
    margin: 10px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87); */
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    width: 50%;
    font-size: 20px;
    /* margin: 10px; */
    border: 1px solid grey;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.87);
}
.p1-range-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.p2-range-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}