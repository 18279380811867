.main {
    position: relative;
}
.home_page {
    position: absolute;
    width: 100%;
    height: 100vh;
    opacity: 0.1;
    top: 30px;
    display: flex;
    
}
.picture {
    position: absolute;
    top: 50vh;
    left: 30vw;
    /* width: 100%;
    height: 100vh; */
    display: flex;
    /* align-items: center;
    justify-content: center; */
}

img {
    width: 40vw;
}