.profile-page {
    display: flex;
    /* width: 400px; */
    width: 360px;
    margin: 80px auto;
    border: 1px solid black;
    border-radius: 20px;
    background-image: linear-gradient(to bottom right,rgba(255, 0, 0, 0.2), white, rgba(0, 128, 0, 0.2));
}
.profile-form {
    /* border: 1px solid red; */
    position: relative;
    width: 360px;
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    margin: 20px auto;
    background-image: linear-gradient(to bottom right,rgba(255, 0, 0, 0.2), white, rgba(0, 128, 0, 0.2));
    /* font-weight: bold; */
    /* margin: 20px auto;
    padding: 20px auto; */ 
}
.profile-form-body {
    width: 360px;
    display: flex;
    flex-direction: column;
}
.profile-form-row {
    display: flex;
    flex-direction: row;
    width: 300px;
    margin: 10px auto;
}
.form__label{
    width: 150px;
}
input {
    width: 170px;
}



.profile_page_login {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    top: 0px;
    display: flex;
    margin-top: 30px;
}
.profile-page {
    position: relative;
    width: 360px;
    margin: 80px auto;
    border: 1px solid black;
    border-radius: 20px;
    background-image: linear-gradient(to bottom right,rgba(255, 0, 0, 0.2), white, rgba(0, 128, 0, 0.2));
    font-weight: bold;
}
.profile-name {
    display: flex;
    justify-content: center;
}
.profil-form-row {
    display: flex;
    flex-direction: row;
    width: 300px;
    margin: 10px auto;
}