.name_container {
  border: 1px solid rgba(3, 24, 12, 0.4);
    border-radius: 20px;
    width: 490px;
    /* min-width: 300px; */
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .name_container_active {
    background-color: rgba(150, 242, 191, 0.4);
  }
  .name_container_passive {
    background-color: rgb(243, 246, 248);
  }
  .name_text {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
  }
  /* @media screen and (min-width: 993px) {
    .name_container {
      border: 1px solid rgba(3, 24, 12, 0.4);
        border-radius: 20px;
        width: 490px;
        min-width: 300px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  } */
/* @media screen and (min-width: 768px) and (max-width: 992px) {
  .name_container {
    border: 1px solid rgba(3, 24, 12, 0.4);
      border-radius: 20px;
      width: 350px;
      min-width: 300px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
} */
/* @media screen and (min-width: 601px) and (max-width: 767px) {
  .name_container {
    border: 1px solid rgba(3, 24, 12, 0.4);
      border-radius: 20px;
      width: 300px;
      min-width: 300px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
} */
@media screen and (max-width: 600px) {
  .name_container {
    border: 1px solid rgba(3, 24, 12, 0.4);
      border-radius: 20px;
      box-sizing: border-box;
      /* min-width: 180px; */
      width: 180px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  .name_text {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 20px;
  }
}
  