.home_page_verification {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    top: 0px;
    display: flex;
    margin-top: 30px;
}
.welcome {
    border: 1px solid red;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 80px auto;
    width: 360px;
    background-image: linear-gradient(to bottom right,rgba(255, 0, 0, 0.3), white, rgba(0, 128, 0, 0.3));
    font-weight: bold;
}
