.statistics_container {
    /* border: 1px solid rgba(3, 24, 12, 0.4);
    border-radius: 5px; */
    width: 1200px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding: 0 50px 0 50px; */
}

.left_stat_container {
    box-sizing: border-box;
    border: 1px solid rgba(3, 24, 12, 0.4);
    
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-right: 1px;
}
.right_stat_container {
    box-sizing: border-box;
    border: 1px solid rgba(3, 24, 12, 0.4);
    
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-left: 1px;
}
.stat_container {
    /* border: 1px solid rgba(3, 24, 12, 0.4); */
    box-sizing: border-box;
    width: 240px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    font-size: 30px;
}

.table_stat{
    width: 100%;
}
.left_column_stat {
    width: 70%;
    padding-left: 5px;
    font-size: 28px;
}
.right_column_stat {
    width: 30%;
    padding-right: 5px;
    text-align: right;
}
.stat_hr {
    color:  rgba(3, 24, 12, 0.4);
}
@media screen and (min-width: 993px) {
    .player1_stat {
        display: flex;
        flex-direction: row;
    }
    .player2_stat {
        display: flex;
        flex-direction: row;
        
    }
}
/* @media screen and (min-width: 768px) and (max-width: 992px) {
    .statistics_container {
        width: 760px;
    }
    
    .stat_container {
        width: 158px;
    }
    .left_stat_container {
        box-sizing: border-box;
        margin: 0;
        
        padding: 0;
    }
    .right_stat_container {
        box-sizing: border-box;
        margin: 0;
        
        padding: 0;
    }
    .player1_stat {
        display: flex;
        flex-direction: row;
    }
    .player2_stat {
        display: flex;
        flex-direction: row;
        
    }
}
@media screen and (min-width: 601px) and (max-width: 767px) {
    .statistics_container {
        width: 600px;
    }
    .stat_container {
        width: 118px;
    }
    .left_stat_container {
        box-sizing: border-box;
        margin: 0;
        
        padding: 0;
    }
    .right_stat_container {
        box-sizing: border-box;
        margin: 0;
        
        padding: 0;
    }
    .player1_stat {
        display: flex;
        flex-direction: row;
    }
    .player2_stat {
        display: flex;
        flex-direction: row;
        
    }
} */
@media screen and (max-width: 600px) {
    .statistics_container {
        width: 360px;
    }
    .stat_container {
        width: 160px;
        font-weight: bold;
        font-size: 18px;
    }
    .left_stat_container {
        margin: none;
    }
    .left_column_stat {
        width: 70%;
        padding-left: 5px;
        font-size: 18px;
    }
    .player1_stat {
        display: flex;
        flex-flow: column-reverse;
    }
    .player2_stat {
        display: flex;
        flex-flow: column;
        
    }
}