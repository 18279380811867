@media screen and (min-width: 993px) {
    .act_state_container {
        width: 1200px;
    }
}
/* @media screen and (min-width: 768px) and (max-width: 992px) {
    .act_state_container {
        width: 760px;
    }
}
@media screen and (min-width: 601px) and (max-width: 767px) {
    .act_state_container {
        width: 600px;
    }
} */
@media screen and (max-width: 600px) {
    .act_state_container {
        width: 360px;
    }
}


.act_state_container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

