

.act_result_container {
    width: 180px;
    height: 100px;
    border: 1px solid rgba(3, 24, 12, 0.4);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}
.p1_result {
    display: flex;
    flex-direction: column;
    font-size: 36px;
    font-weight: bold;
    padding-left: 20px;
}
.p2_result {
    display: flex;
    flex-direction: column;
    font-size: 36px;
    font-weight: bold;
    padding-right: 20px;
}
.text_result {
    color: rgb(19, 19, 20);
    font-size: 36px;
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
}

/* @media screen and (min-width: 993px) {
    .act_result_container {
        width: 180px;
    }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
    .act_result_container {
        width: 180px;
    }
}
@media screen and (min-width: 601px) and (max-width: 767px) {
    .act_result_container {
        width: 180px;
    }
} */
@media screen and (max-width: 600px) {
    .act_result_container {
        width: 100px;
        height: 80px;
        
    }
    .p1_result {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
    }
    .p2_result {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        font-weight: bold;
        padding-right: 10px;
    }
    .text_result {
        color: rgb(19, 19, 20);
        font-size: 20px;
        font-weight: bold;
        padding-left: 5px;
        padding-right: 5px;
    }
}