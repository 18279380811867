

.score_container {
  margin-top: 5px;
  border: 1px solid rgba(3, 24, 12, 0.4);
    border-radius: 20px;
    height: 160px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .score_container_active {
    background-color: rgb(150, 242, 191, 0.4);
  }
  .score_container_passive {
    background-color: rgb(243, 246, 248);
  }
  .score_text {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 120px;
  }
  /* @media screen and (min-width: 993px) {
    .score_container {
      width: 200px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .score_container {
      width: 200px;
    }
  }
  @media screen and (min-width: 601px) and (max-width: 767px) {
    .score_container {
      width: 200px;
    }
  } */
  @media screen and (max-width: 600px) {
    .score_container {
      box-sizing: border-box;
      width: 130px;
      height: 80px;
      margin-top: 0px;
    }
    .score_text {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      font-size: 78px;
    }
  }