.Modal-dialog {
    width: 300px;
    height: 210px;
    margin-top: 300px;
    margin-left: auto;
    margin-right: auto;
    background-color: aliceblue;
    z-index: 0;
    
}
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: antiquewhite;
    opacity: 1;
    z-index: 10;
}
.modal-content {
    width: 280px;
    height: 190px;
    border: 1px solid red;
    border-radius: 10px;
    padding: 10px;
}
.warning-title {
    color: red;
    font-size: 32px;
    display: flex;
    justify-content: center;
}
.warning-text {
    display: flex;
    justify-content: center;
    padding-left: 10px;
}
.modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
}