.languages {
    display: flex;
    flex-direction: row;
    width: 50px;
    justify-content: space-between;

}
#hu-flag {
    width: 20px;  
    cursor: pointer;  
}
#uk-flag {
    width: 20px;   
    cursor: pointer; 
}