.match-results-screen {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.match-results-title {
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
}
/* .match-list-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
} */
.match-stat-container {
    display: flex;
    flex-direction: row;
    width: 96%;
    height: 800px;
    justify-content: space-between;
    margin: 10px;
    box-sizing: border-box;
    border: 1px solid black;
}
.match-container {
    display: flex;
    width: 600px;
    flex-direction: column;
    box-sizing: border-box;
   /*  border: 1px solid blue; */
}
.match-list-container {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 70px;
    box-sizing: border-box;
    padding: 5px;
    /* border: 1px solid green; */
}
.match-select {
    width: 590px;
    font-size: 18px;
}
.match-filters {
    width: 600px;
    height: 20px;
    box-sizing: border-box;
    /* border: 1px solid red; */
}
.match-status-container {
    height: 600px;
    box-sizing: border-box;
    padding: 5px;
    /* border: 1px solid red; */
}
.match-score-container {
    width: 700px;
    height: 800px;
    overflow-y: auto;
    box-sizing: border-box;
    /* border: 1px solid red; */
}