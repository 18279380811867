/* 
@media only screen and (max-width: 600px)
@media only screen and (min-width: 600px)
@media only screen and (min-width: 768px)
@media only screen and (min-width: 992px)
@media only screen and (min-width: 1200px)
@media only screen and (min-width : 1824px)

@media only screen and (orientation: landscape | portrait) {
   ...
}

*/

@media only screen and (min-width: 993px) {
  .hotkey_container {
    border: 2px solid black;
    border-radius: 5px;
    width: 90px;
    height: 24px;
    margin: 0 2px 2px 2px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
  }
  .extra_hotkey_container {
    border: 2px solid black;
    border-radius: 5px;
    width: 145px;
    height: 24px;
    margin: 0 2px 2px 2px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .hotkey_container {
    border: 2px solid black;
    border-radius: 5px;
    width: 80px;
    height: 20px;
    margin: 0 2px 2px 2px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .extra_hotkey_container {
    border: 2px solid black;
    border-radius: 5px;
    width: 140px;
    height: 20px;
    margin: 0 2px 2px 2px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
  .hotkey_container {
    border: 2px solid black;
    border-radius: 5px;
    width: 75px;
    height: 20px;
    margin: 0 2px 2px 2px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .extra_hotkey_container {
    border: 2px solid black;
    border-radius: 5px;
    width: 140px;
    height: 20px;
    margin: 0 2px 2px 2px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}
@media only screen and (max-width: 600px) {
  .hotkey_container {
    border: 2px solid black;
    border-radius: 5px;
    width: 60px;
    height: 20px;
    margin: 0 2px 2px 2px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .extra_hotkey_container {
    border: 2px solid black;
    border-radius: 5px;
    width: 120px;
    height: 20px;
    margin: 0 2px 2px 2px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
}

.hotkey_text {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 18px;
}
