.forget_page_login {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    top: 0px;
    display: flex;
    margin-top: 30px;
}
.forget-page {
    position: relative;
    width: 360px;
    margin: 80px auto;
    border: 1px solid black;
    border-radius: 20px;
    background-image: linear-gradient(to bottom right,rgba(255, 0, 0, 0.2), white, rgba(0, 128, 0, 0.2));
    font-weight: bold;
}
.my-forget-form {
    width: 320px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    margin: 20px auto;
    padding: 20px auto; 
    background-image: linear-gradient(to bottom right,rgba(255, 0, 0, 0.2), white, rgba(0, 128, 0, 0.2));
    font-weight: bold;
}
.my-forget-form-body {
    width: 360px;
    display: flex;
    flex-direction: column;
}
.form-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.btn-forget {
    width: 100px;
}

.my-form-row {
    display: flex;
    width: 280px;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px auto;
}
.email-error-message {
    display: flex;
    justify-content: center;
    color: red;
}