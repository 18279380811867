
#scoreinput {
    color: brown;
    font-size: 90px;
    width: 140px;
    height: 150px;
    text-align: center;
    /* border-color: rgb(0, 17, 255); */
    /* border-radius: 3px; */
    /* outline: none; */
    border: 0;
}
.input_field_container {
    box-sizing: border-box;
    border: 1px solid rgba(3, 24, 12, 0.4);
    border-radius: 5px;
    width: 150px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
}
textarea:focus, input:focus {
    outline: none;
}
@media screen and (min-width: 993px) {
    /* width: 900px; */
}
@media screen and (min-width: 768px) and (max-width: 992px) {
    /* width: 760px; */
}
@media screen and (min-width: 601px) and (max-width: 767px) {
    /* width: 600px; */
}
@media screen and (max-width: 600px) {
    /*  width: 360px; */
    .input_field_container {
        width: 100px;
        height: 85px;
    }
    #scoreinput {
        /* border: 3px solid red; */
        font-size: 30px;
        width: 50px;
        height: 50px;
    }
}