.leg-result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.two-tables {
    display: flex;
    width: 600px;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 60px;
    margin-right: 60px;
}
.table-one {
    display: flex;
    flex-direction: column;
    width: 240px;
    flex: 1;
    padding: 20px;
}

.table-score1 tr  {
  height: 35px;
}
.table-score2 tr  {
  height: 35px;
}
.table-score1 {
  border: 1px solid black;
}
.table-score2 {
  border: 1px solid black;
}
.col1-1 {
  width: 80px;
  border: 1px solid black;
  text-align: center;
  font-size: 24px;
}
.col2-1 {
  width: 160px;
  border: 1px solid black;
  text-align: center;
  font-size: 24px;
}
.col1-2 {
  width: 80px;
  border: 1px solid black;
  text-align: center;
  font-size: 24px;
}
.col2-2 {
  width: 160px;
  border: 1px solid black;
  text-align: center;
  font-size: 24px;
}
