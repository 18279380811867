.score_check_container {
    /* border: 1px solid red; */
    width: 1200px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 10px;
    /* padding: 0 50px 0 50px; */
}
@media screen and (max-width: 600px) {
    .score_check_container {
        width: 360px;
    }
}