*:active {
    outline:none !important;
}
.virt-kbd {
    display: none;

    /* display: flex;
    flex-direction: column; */
}
.kbd-row {
    display: flex;
    flex-direction: row;
}
.kbd-button {
    width: 90px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(105, 105, 216, 0.104);
    box-sizing: border-box;
    border-radius: 15px;
}.kbd-button:active {
    outline:none !important;
}
.num-button {
    width: 80px;
    height: 50px;
    border-radius: 15px;
    border-color: rgba(133, 138, 142, 0.806);
    font-size: 40px;
    background-color: rgb(243, 246, 248);
}
.num-button:active {
    width: 85px;
    height: 55px;
    background-color: rgba(150, 242, 191, 0.4);
    outline:none !important;
}
.show-button {
    width: 360px;
    height: 50px;
    border-radius: 25px;
    border-color: rgba(133, 138, 142, 0.806);
    font-size: 30px;
    background-color: rgb(243, 246, 248);
}
.show-button:active {
    background-color: rgba(150, 242, 191, 0.4);
}
.textbtn {
    font-size: 18px;
}
.textbtn:active {
    outline:none !important;
}


@media screen and (max-width: 600px) {
    .virt-kbd {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 0;
    }
    .virt-kbd-none {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 7px;
        left: 10px;
    }
}