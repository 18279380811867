.settings-screen{
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.home_page_settings {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.form-container {
    position: relative;
    width: 360px;
    padding-top: 80px;
    display: flex;
    height: 100%;
    /* border: 2px solid green; */
    align-items: center;
    justify-content: center;
    /* font-weight: bold; */
    
}
.settings-form {
    background-image: linear-gradient(to bottom right,rgba(255, 0, 0, 0.2), white, rgba(0, 128, 0, 0.2));
    /* font-weight: bold; */
    border: 1px solid black;
    border-radius: 20px;
    padding-bottom: 20px;
}
.form-row {
    width: 350px;
    /* border: 1px solid lightblue; */
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.form-row-player {
    width: 350px;
    /* border: 1px solid lightblue; */
    padding-left: 20px;
    padding-top: 10px;
    
}
.form-row-variant {
    width: 350px;
    /* border: 1px solid lightblue; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    font-weight: bold;
    
}
.form-row-gamename {
    width: 350px;
    padding-left: 20px;
    padding-top: 10px;
}
.gamename {
    width: 340px;
}
.text-for-input {
    padding-left: 20px;
    padding-top: 10px;
}
.variant-item {
    display: flex;
    width: 60px;
    justify-content: flex-start;
    align-items: start;
}
.variant-item input {
    width: 20px;
}
.form-row-button {
    /* padding-top: 30px; */
    display: flex;
    /* width:40px;
    height: 40px; */
    flex-direction: row;
    justify-content: center;
    background-size: contain;
    
}
.start-button {
    border: none;
    padding: 0;
    width:40px;
    height: 40px;
    border-radius: 20px;
    background-color: white;
    
}

.start-button img{
    width:40px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
}

.start-button img:hover {
    rotate: 360deg;
    transition: 1s;
}
.start-button {
    /* width:40px;
    height: 40px;
    border-radius: 20px;
    background-image: url("./dart.svg"); */
    /* background-color: aquamarine; */
}